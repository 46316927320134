import React, { FC, useEffect, useRef, useState, useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import useTitle from 'react-use/lib/useTitle'
import { find } from 'lodash/fp'

import Splash from '../../components/Splash'
import Pager from '../../components/Pager'
import { ICursoryOrder } from '../../types/orders'
import { IBranch, IAccount, IUser } from '../../types/user'
import withAccountAndBranch from '../../common/withAccountAndBranch'
import * as storage from '../../common/storage'
import {
  requestFilterOrders,
  FilterOrdersReq,
  IOrdersList,
  IOrdersFilter,
  requestOrderCreate,
} from './ordersActions'
import OrdersFilter, { FILTER_STORAGE_KEY } from './OrdersFilter'
import OrdersRow from './OrdersRow'
import SubmittedOrdersRow from './SubmittedOrdersRow'
import OrdersPlaceholder from './OrdersPlaceholder'
import useThunkDispatch from '../../common/useThunkDispatch'
import { getShouldRenderNewReplenishmentPlan } from '../../common/getShouldRenderNewReplenishmentPlan'
import { Redirect } from 'react-router-dom'

interface IOrdersProps extends RouteComponentProps {
  user: IUser
  account?: IAccount
  branch?: IBranch
}

const PER_PAGE = 25

const Orders: FC<IOrdersProps> = ({
  user,
  account: currentAccount,
  branch,
  match,
  history,
  location,
}) => {
  useTitle('Orders - Forshaw')
  const dispatch = useThunkDispatch()
  const storageFilters = JSON.parse(
    storage.get(FILTER_STORAGE_KEY, '{"scope":"manageable"}')
  )
  const [filter, setFilter] = useState<IOrdersFilter>(storageFilters)
  const [list, setList] = useState<IOrdersList>({
    orders: [],
    paginationState: {
      endCursor: '',
      startCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    total: 0,
  })
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const listRef = useRef<FixedSizeList>(null)
  const nothingFound = !list.orders.length && !loading
  const listStyles = { height: 'calc(100vh - 300px)' }
  const account = currentAccount || user.account
  const gmFlow = account && account.orderApproveFlow

  const shouldRenderNewReplenishmentPlan = account
    ? getShouldRenderNewReplenishmentPlan(account.replenishmentPlanVersion)
    : false

  // load orders call
  const loadOrders = async (req: FilterOrdersReq) => {
    setLoading(true)
    if (listRef.current) listRef.current.scrollToItem(0)
    const list = (await dispatch(
      requestFilterOrders({
        ...req,
        branchId: (branch && branch.id) || undefined,
        accountId: (account && account.id) || undefined,
      })
    )) as any
    setList(list)
    setLoading(false)
  }

  // previous page
  const handlePrevious = useCallback(() => {
    loadOrders({
      last: PER_PAGE,
      before: list.paginationState.startCursor,
      ...filter,
    })
    setPage(page - 1)
  }, [list, filter, page])

  // next page
  const handleNext = useCallback(() => {
    loadOrders({
      first: PER_PAGE,
      after: list.paginationState.endCursor,
      ...filter,
    })
    setPage(page + 1)
  }, [list, filter, page])

  // on filter change
  const handleFilterChange = (term: string, filterOption: IOrdersFilter) => {
    setFilter({ ...filter, text: term, ...filterOption })
  }

  // initial load
  useEffect(() => {
    if (branch) {
      loadOrders({ first: PER_PAGE, ...filter })
    }
  }, [filter, branch && branch.id, location.key])

  if (!branch) return <Splash isLoading={true} />

  const fillInOrder = find(
    order =>
      order.kind === 'fill_in' &&
      order.status !== 'submitted' &&
      order.status !== 'skipped' &&
      branch &&
      order.branch.id === branch.id,
    list.orders || []
  )

  // on update
  const handleCreateFillIn = async () => {
    const { result, errors } = await dispatch(requestOrderCreate(branch.id))

    if (result) {
      history.push(`${match.url}/${result.id}`)
    } else if (errors) {
      alert(errors)
    }
  }

  const handleClickFillIn = () => {
    if (fillInOrder) {
      history.push(`${match.url}/${fillInOrder.id}`)
    } else {
      handleCreateFillIn()
    }
  }

  const branchSelected = branch && branch.id > 0
  const allBranches = !branchSelected

  // Users should not see orders page if they can see new replenishment plan
  // if (shouldRenderNewReplenishmentPlan) {
  //   const newLocation = location.pathname.replace('/orders', '/inventory')
  //
  //   return <Redirect to={newLocation} />
  // }

  return (
    <div className="container">
      <OrdersFilter
        onChange={handleFilterChange}
        fillInOrder={fillInOrder}
        onClickFillIn={handleClickFillIn}
        branchSelected={branchSelected}
      />

      {!nothingFound && (
        <div className="table">
          {filter.scope === 'submitted' ? (
            <div className="table_head-tr">
              <div className="table_td -fix50" />
              <div className="table_td table_title -g20">Submited on</div>
              {allBranches && (
                <div className="table_td table_title -g25">Branch</div>
              )}
              <div className="table_td table_title -g25">Last Updated by</div>
              {/* {gmFlow && (
                <div className="table_td table_title -g25">GM Approval by</div>
              )} */}
              <div className="table_td table_title -g20">Status</div>
              <div className="table_td table_title -g20">Sales Order #</div>
              <div className="table_td table_title -g20">PO #</div>
              <div className="table_td table_title -g20">Total</div>
              <div className="table_td table_title -g25">Shipping Info</div>
            </div>
          ) : (
            <div className="table_head-tr">
              <div className="table_td -fix50" />
              <div className="table_td table_title -g20">Est. Shipment</div>
              {allBranches && (
                <div className="table_td table_title -g25">Branch</div>
              )}
              <div className="table_td table_title -g25">Last Updated by</div>
              {/* {gmFlow && (
                <div className="table_td table_title -g25">GM Approval by</div>
              )} */}
              <div className="table_td table_title -g20">Status</div>
              <div className="table_td table_title -g20">PO #</div>
              <div className="table_td table_title -g20">Total</div>
            </div>
          )}
        </div>
      )}

      <div className="table_loading-wrapper">
        {nothingFound ? (
          <OrdersPlaceholder
            message={
              filter.scope === 'pending_approval'
                ? 'New Pending Requests will appear here'
                : 'No orders submitted this month'
            }
            style={listStyles}
          />
        ) : (
          <AutoSizer className="table" style={listStyles}>
            {size => (
              <FixedSizeList
                ref={listRef}
                className="wonder-scroll"
                {...size}
                itemData={{
                  orders: list.orders,
                  onClick: (order: ICursoryOrder) =>
                    history.push(`${match.url}/${order.id}`),
                  allBranches,
                  gmFlow,
                }}
                itemCount={list.orders.length}
                itemSize={55 + 17}
                itemKey={index => list.orders[index].id}
              >
                {filter.scope === 'submitted' ? SubmittedOrdersRow : OrdersRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
        <Splash isLoading={loading} bgColor="#F2F6FA" />
      </div>

      {!nothingFound && (
        <Pager
          page={page}
          total={list.total}
          perPage={PER_PAGE}
          previousEnabled={list.paginationState.hasPreviousPage && !loading}
          nextEnabled={list.paginationState.hasNextPage && !loading}
          onNext={handleNext}
          onPrevious={handlePrevious}
          className="-right"
        />
      )}
    </div>
  )
}

export default withAccountAndBranch(Orders)
