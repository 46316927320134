import { IAccount, IBranch, IUser } from './user'
import { IOrderedProduct, IProduct } from './products'
import { getMonth, getYear } from 'date-fns'
import { sumBy } from 'lodash/fp'
import useMonthInfo from '../common/useMonthInfo'

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    ADD_TO_SHOP_BRANCH_ID: number
  }
}

export type IOrderStatus =
  | 'open'
  | 'delayed'
  | 'approved'
  | 'requested'
  | 'rejected'
  | 'skipped'
  | 'submitted'
export type IOrderKind = 'replenish' | 'fill_in'

export interface IOrder {
  id: number
  year: number
  month: number
  name: string
  kind: IOrderKind
  status: IOrderStatus
  navOrderId?: string
  navReferenceId?: string
  updatedAt?: string
  updatedBy?: IUser
  requestedAt?: string
  requestedBy?: IUser
  rejectReason?: string
  approvedAt?: string
  approvedBy?: IUser
  submittedAt?: string
  submittedBy?: IUser
  autoSubmitted?: boolean
  dueDateAt?: string
  shipmentDateAt?: string
  approveDueDateAt?: string
  orderItems: IOrderItem[]
  orderTrackingStatuses: IOrderTrackingStatus[]
  cost: number
}

export interface IOrderAccess {
  canRequest?: boolean
  canEdit?: boolean
  canReopen?: boolean
  canUrgent?: boolean
  canReject?: boolean
  canApprove?: boolean
  canAddToShop?: boolean
  canSubmit?: boolean
  approveText?: string
}

export type IPlannedOrder = IOrder & {
  kind: 'replenish'
  dueDateAt: string
  orderItems: IPlannedOrderItem[]
}
export type IFillinOrder = ISubmittedOrder & { kind: 'fill_in' }
export type ICursoryOrder = IOrder & {
  orderItems?: IOrderItem[]
  branch: IBranch
  account: IAccount
}
export interface IDetailsOrder extends IOrder {
  branch: IBranch
  account: IAccount
  navCsv?: string
}

export type IIdentifiableOrderAttrs = { id: number } & Partial<IOrder>

export type ISubmittedOrder = IOrder & {
  status: 'submitted'
  submittedAt: string
  submittedBy: IUser
  updatedAt: string
  updatedBy: IUser
}
export type IApprovedOrder = IOrder & {
  status: 'approved'
  approvedAt: string
  approvedBy: IUser
  updatedAt: string
  updatedBy: IUser
}

export type IStatusableOrder = Pick<
  IOrder,
  'kind' | 'status' | 'dueDateAt' | 'submittedAt'
>
export type IKindableOrder = Pick<IOrder, 'kind'>

export const isPlannedOrder = (
  order: Partial<IOrder>
): order is IPlannedOrder => order.kind === 'replenish'
export const isFillInOrder = (order: Partial<IOrder>): order is IFillinOrder =>
  order.kind === 'fill_in'
export const isCursoryOrder = (
  order: Partial<IOrder>
): order is ICursoryOrder =>
  !!(order as ICursoryOrder).branch && !!(order as ICursoryOrder).account

export const isEditableOrder = (order: Partial<IOrder>): boolean =>
  order.status !== 'skipped' &&
  order.status !== 'submitted' &&
  order.status !== 'requested' &&
  order.status !== 'approved' &&
  order.status !== 'rejected'
export const isSubmittedOrder = (
  order: Partial<IOrder>
): order is ISubmittedOrder => order.status === 'submitted'
export const isApprovedOrder = (
  order: Partial<IOrder>
): order is IApprovedOrder => order.status === 'approved'
export const isRejectedOrder = (
  order: Partial<IOrder>
): order is IApprovedOrder => order.status === 'rejected'
export const isRequestedOrder = (
  order: Partial<IOrder>
): order is IApprovedOrder => order.status === 'requested'
export const isSkippedOrder = (
  order: Partial<IOrder>
): order is IOrder & { status: 'skipped' } => order.status === 'skipped'
export const isDelayedOrder = (
  order: Partial<IOrder>
): order is IOrder & { status: 'delayed' } => order.status === 'delayed'
export const isOpenOrder = (
  order: Partial<IOrder>
): order is IOrder & { status: 'open' } => order.status === 'open'
export const isReplenishSubmittedOrder = (order: Partial<IOrder>): boolean =>
  order.status === 'submitted'

export const fetchFlowAccess = (
  account: IAccount,
  user: IUser,
  order: IDetailsOrder
): IOrderAccess => {
  const gmFlow = account.orderApproveFlow

  const month = getMonth(String(order.shipmentDateAt)) + 1
  const year = getYear(String(order.shipmentDateAt))
  const { isCurrentMonth, isFutureMonth } = useMonthInfo({ year, month })

  const isEmpty = sumBy('amountRequested', order.orderItems) === 0

  if (user.role === 'internal') {
    return {
      canEdit: ['open', 'delayed', 'requested'].includes(order.status),
    }
  }

  if (gmFlow && user.role === 'manager' && !user.generalManager) {
    // simple manager with gm ON

    if (order.status === 'open') {
      return {
        canRequest: !isEmpty,
        canEdit: true,
      }
    }

    if (order.status === 'delayed') {
      return {
        canRequest: !isEmpty,
        canEdit: true,
      }
    }

    if (order.status === 'requested') {
      return {
        canReopen: true,
      }
    }

    if (order.status === 'approved') {
      return {
        canReopen: true,
        canUrgent: !isEmpty && isCurrentMonth && order.kind === 'replenish',
      }
    }

    if (order.status === 'rejected') {
      return {
        canReopen: true,
      }
    }
  }

  if (gmFlow && user.role === 'manager' && user.generalManager) {
    // simple manager with gm OFF

    if (order.status === 'open') {
      return {
        canApprove: !isEmpty,
        canAddToShop:
          order.branch.id === window.ADD_TO_SHOP_BRANCH_ID && !isEmpty,
        canEdit: true,
        approveText:
          order.kind === 'replenish' ? 'Approve Order' : 'Place Order',
      }
    }

    if (order.status === 'delayed') {
      return {
        canApprove: !isEmpty,
        canEdit: true,
        approveText:
          order.kind === 'replenish' ? 'Approve Order' : 'Place Order',
      }
    }

    if (order.status === 'requested') {
      return {
        canEdit: true,
        canApprove: !isEmpty,
        canReject: !isEmpty,
        approveText: 'Approve',
      }
    }

    if (order.status === 'approved') {
      return {
        canUrgent: !isEmpty && isCurrentMonth && order.kind === 'replenish',
        canEdit: true,
        canReject: true,
      }
    }

    if (order.status === 'rejected') {
      return {
        canEdit: true,
        canApprove: !isEmpty,
        approveText: 'Approve',
      }
    }
  }

  if (!gmFlow && user.role === 'manager') {
    // simple manager

    if (order.status === 'open') {
      return {
        canEdit: true,
        canApprove:
          !isEmpty &&
          (isCurrentMonth || isFutureMonth) &&
          order.kind === 'replenish',
        canAddToShop:
          order.branch.id === window.ADD_TO_SHOP_BRANCH_ID && !isEmpty,
        canSubmit: !isEmpty && order.kind === 'fill_in',
        approveText:
          order.kind === 'replenish' ? 'Approve Order' : 'Place Order',
        canUrgent: !isEmpty && isCurrentMonth && order.kind === 'replenish',
      }
    }

    if (order.status === 'delayed') {
      return {
        canEdit: true,
        canApprove: !isEmpty && order.kind === 'replenish',
        approveText:
          order.kind === 'replenish' ? 'Approve Order' : 'Place Order',
        canUrgent: !isEmpty && isCurrentMonth && order.kind === 'replenish',
      }
    }

    if (order.status === 'approved') {
      return {
        canReopen: true,
        canUrgent: !isEmpty && isCurrentMonth && order.kind === 'replenish',
      }
    }
  }

  return {}
}

export interface IOrderItem {
  id: number
  product: IOrderedProduct
  productId?: number
  updatedAt: string
  updatedBy: IUser
  price?: number
  unit?: string

  amountRequested: number

  orderId?: number
  forecast?: IItemForecast
}

export interface IOrderTrackingStatus {
  id: number
  status?: string
  shippedAt?: string
  trackingNumber?: string
  shippingAgent?: string
  soNumber?: string
  trackingUrl?: string
}
export interface IPlannedOrderItem extends IOrderItem {
  forecast: IItemForecast
}

export interface IItemForecast {
  amountSuggested: number
  amountMinSuggested: number
  amountMaxSuggested: number
}

export interface IBranchForecast extends IItemForecast {
  id: number
  year: number
  month: number
  productId: number
}

export interface IBranchShipment {
  id: number
  date: string
  productId: number
  amount: number
}

export const isPlannedOrderItem = (
  itemOrProduct: Partial<IOrderItem | IProduct>
): itemOrProduct is IPlannedOrderItem =>
  isOrderItem(itemOrProduct) && !!itemOrProduct.forecast
export const isOrderItem = (
  itemOrProduct: Partial<IOrderItem | IProduct>
): itemOrProduct is IOrderItem => !!(itemOrProduct as IOrderItem).product
